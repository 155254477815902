'use client'

import { useState } from 'react'
import { Search, MoreVertical } from 'lucide-react'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'

import { Input } from "./components/ui/Input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./components/ui/Table"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "./components/ui/Card"
import { Badge } from "./components/ui/Badge"
import { Button } from "./components/ui/Button"

// Mock data for jobs
const initialJobs = [
  { 
    id: 1, 
    role: 'Production Co-Ordinator', 
    production: 'Survivor Antarctica', 
    startDate: new Date('2024-03-01'), 
    endDate: new Date('2024-06-30'), 
    status: 'Open', 
    budget: '500', 
    budgetRate: 'per day', 
    location: 'Sydney',
    description: 'We are seeking an experienced Production Co-ordinator for Survivor Antarctica. This role requires excellent organizational skills and previous experience in managing large-scale productions.'
  },
  { 
    id: 2, 
    role: 'Camera Operator', 
    production: 'The Voice', 
    startDate: new Date('2024-04-15'), 
    endDate: new Date('2024-08-15'), 
    status: 'Filled', 
    budget: '600', 
    budgetRate: 'per day', 
    location: 'Melbourne',
    description: 'Experienced Camera Operator needed for The Voice Australia. Must have previous broadcast experience and be comfortable working in a fast-paced environment.'
  },
  { 
    id: 3, 
    role: 'Sound Mixer', 
    production: 'Master Chef', 
    startDate: new Date('2024-05-01'), 
    endDate: new Date('2024-07-31'), 
    status: 'Open', 
    budget: '550', 
    budgetRate: 'per day', 
    location: 'Brisbane',
    description: 'Looking for a skilled Sound Mixer for Master Chef Australia. Experience in reality TV production and working in kitchen environments is essential.'
  },
]

export default function JobsPage() {
  const navigate = useNavigate()
  const [jobs] = useState(initialJobs)
  const [searchTerm, setSearchTerm] = useState('')

  const filteredJobs = jobs.filter(job => 
    Object.values(job).some(value => 
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  )

  const handleRowClick = (jobId: number) => {
    navigate(`/listings/${jobId}`)
  }

  return (
    <div className="container mx-auto py-10">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold">Jobs</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex items-center py-4">
            <Input
              placeholder="Search jobs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="max-w-sm"
            />
            <Search className="ml-2 h-4 w-4 text-muted-foreground" />
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[200px] whitespace-nowrap">Role</TableHead>
                <TableHead>Production</TableHead>
                <TableHead>Location</TableHead>
                <TableHead>Dates</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Budget</TableHead>
                <TableHead className="w-[50px]"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredJobs.map((job) => (
                <TableRow 
                  key={job.id}
                  className="cursor-pointer hover:bg-accent"
                  onClick={() => handleRowClick(job.id)}
                >
                  <TableCell className="font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px]">
                    {job.role}
                  </TableCell>
                  <TableCell>{job.production}</TableCell>
                  <TableCell>{job.location}</TableCell>
                  <TableCell>
                    {format(job.startDate, 'dd-MM-yy')} to {format(job.endDate, 'dd-MM-yy')}
                  </TableCell>
                  <TableCell>
                    <Badge variant={job.status === 'Open' ? 'default' : 'secondary'}>
                      {job.status}
                    </Badge>
                  </TableCell>
                  <TableCell>{`$${job.budget} ${job.budgetRate}`}</TableCell>
                  <TableCell>
                    <Button variant="ghost" size="icon">
                      <MoreVertical className="h-4 w-4" />
                      <span className="sr-only">Open menu</span>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}