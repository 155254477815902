import { useParams, useNavigate } from 'react-router-dom';
import { Button } from "./components/ui/Button";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "./components/ui/Card";
import { useState, useEffect } from 'react';
import CandidatesPage from './CandidatesPage';

interface JobDetails {
  id: number;
  job: string;
  production: string;
  startDate: string;
  endDate: string;
  location: string;
  budget: string;
  budgetRate: string;
  description: string;
}

// Simulated database of jobs
const jobsDatabase: JobDetails[] = [
  {
    id: 1,
    job: "Camera Operator",
    production: "Survivor Antarctica",
    startDate: "2024-11-01",
    endDate: "2025-02-28",
    location: "Antarctica",
    budget: "500",
    budgetRate: "per day",
    description: "We're seeking an experienced camera operator for our upcoming season of Survivor, set in the challenging environment of Antarctica. The ideal candidate should have experience in extreme weather conditions and be able to capture compelling footage in a fast-paced reality TV setting.",
  },
  {
    id: 2,
    job: "Sound Recordist",
    production: "Desert Warriors",
    startDate: "2024-12-15",
    endDate: "2025-03-15",
    location: "Dubai",
    budget: "650",
    budgetRate: "per day",
    description: "Experienced sound recordist needed for high-profile desert documentary series. Must be comfortable working in extreme heat and challenging desert conditions.",
  }
];

export default function ListingDetailInternal() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState<JobDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Simulate API call to fetch job details
    const fetchJobDetails = () => {
      setLoading(true);
      try {
        const foundJob = jobsDatabase.find(job => job.id === Number(id));
        if (foundJob) {
          setJobDetails(foundJob);
          setError(null);
        } else {
          setError('Job not found');
          setJobDetails(null);
        }
      } catch (err) {
        setError('Error loading job details');
        setJobDetails(null);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [id]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-GB', { 
      day: '2-digit', 
      month: '2-digit', 
      year: '2-digit' 
    });
  };

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  if (error) {
    return (
      <div className="p-4">
        <Button onClick={() => navigate('/listings')} className="mb-4">
          Back to Listings
        </Button>
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  if (!jobDetails) {
    return (
      <div className="p-4">
        <Button onClick={() => navigate('/listings')} className="mb-4">
          Back to Listings
        </Button>
        <div>Job not found</div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <Button 
        onClick={() => navigate('/listings')}
        className="mb-4"
      >
        Back to Listings
      </Button>
      
      <Card>
        <CardHeader className="pb-2">
          <CardTitle>{jobDetails.job}</CardTitle>
          <CardDescription className="text-base">{jobDetails.production}</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4 pt-0">
          <div className="flex items-center space-x-2">
            <span className="font-semibold">Dates:</span>
            <span>
              {formatDate(jobDetails.startDate)} - {formatDate(jobDetails.endDate)}
            </span>
          </div>
          <div>
            <strong>Location:</strong> {jobDetails.location}
          </div>
          <div>
            <strong>Budget:</strong> ${jobDetails.budget} {jobDetails.budgetRate}
          </div>
          <div>
            <strong>Job Description:</strong>
            <p className="mt-2">{jobDetails.description}</p>
          </div>
        </CardContent>
      </Card>
      <CandidatesPage />
    </div>
  );
}