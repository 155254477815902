import React from 'react';
import { Link } from 'react-router-dom';

interface NavigationProps {
  isAuthenticated: boolean;
  onLogout: () => void;
}

const Navigation: React.FC<NavigationProps> = React.memo(({ isAuthenticated, onLogout }) => {
  return (
    <nav className="bg-gray-800 p-4">
      <ul className="flex space-x-4">
        <li>
          <Link to="/" className="text-white hover:text-gray-300">Home</Link>
        </li>
        {isAuthenticated && (
          <>
            <li>
              <Link to="/profile" className="text-white hover:text-gray-300">Profile</Link>
            </li>
            <li>
              <Link to="/listings" className="text-white hover:text-gray-300">Listings</Link>
            </li>
            {/* <li>
              <Link to="/example-public-listing" className="text-white hover:text-gray-300">Public Listing Example</Link>
            </li> */}
          </>
        )}
        <li>
          {isAuthenticated ? (
            <button onClick={onLogout} className="text-white hover:text-gray-300">Logout</button>
          ) : (
            <Link to="/" className="text-white hover:text-gray-300">Login</Link>
          )}
        </li>
      </ul>
    </nav>
  );
});

export default Navigation;