import { useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/Card"
import { Badge } from "./components/ui/Badge"
import { Button } from "./components/ui/Button"
import { Input } from "./components/ui/Input"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./components/ui/Table"
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetFooter } from "./components/ui/Sheet"
import { Separator } from "./components/ui/Separator"
import { FileText, MoreVertical, Search, X } from "lucide-react"

interface Experience {
  productionName: string;
  startYear: string;
  role: string;
  showReel: string;
}

interface Candidate {
  id: number;
  name: string;
  location: string;
  jobTitle: string;
  email: string;
  rate: string;
  status: string;
  phone: string;
  experience: string;
  skills: string[];
  availability: string;
  notes: string;
  // Additional fields for detailed view
  experiences: Experience[];
  cv: string;
  dayRate: string;
}

export default function CandidatesPage() {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Enhanced mock data with experiences array
  const candidates: Candidate[] = [
    {
      id: 1,
      name: "John Doe",
      location: "Toronto, ON",
      jobTitle: "Camera Operator",
      email: "john@example.com",
      rate: "$500/day",
      status: "Applied",
      phone: "+1 (555) 123-4567",
      experience: "5 years",
      skills: ["4K filming", "Drone operation", "Underwater photography"],
      availability: "Immediate",
      notes: "Worked on 3 seasons of Arctic Explorers",
      dayRate: "$500",
      cv: "john_doe_cv.pdf",
      experiences: [
        {
          productionName: "Arctic Explorers",
          startYear: "2022",
          role: "Camera Operator",
          showReel: "https://example.com/reel1"
        },
        {
          productionName: "Mountain Survivors",
          startYear: "2021",
          role: "Junior Camera Operator",
          showReel: "https://example.com/reel2"
        }
      ]
    },
    // ... other candidates with similar detailed structure
  ]

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Applied": return "bg-blue-100 text-blue-800"
      case "Reviewed": return "bg-yellow-100 text-yellow-800"
      case "Shortlisted": return "bg-green-100 text-green-800"
      case "Rejected": return "bg-red-100 text-red-800"
      default: return "bg-gray-100 text-gray-800"
    }
  }

  const filteredCandidates = candidates.filter(candidate =>
    candidate.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    candidate.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
    candidate.rate.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const openCandidateDrawer = (candidate: Candidate) => {
    setSelectedCandidate(candidate)
    setDrawerOpen(true)
  }

  return (
    <div className="py-8">
      <Card>
        <CardHeader>
          <CardTitle>Candidates</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="mb-4 flex items-center space-x-2">
            <Search className="text-gray-400" />
            <Input
              placeholder="Search by name, location, or rate"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex-grow"
            />
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Location</TableHead>
                <TableHead>Job Title</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Rate</TableHead>
                <TableHead>CV</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredCandidates.map((candidate) => (
                <TableRow key={candidate.id} className="cursor-pointer hover:bg-gray-100" onClick={() => openCandidateDrawer(candidate)}>
                  <TableCell className="font-medium">{candidate.name}</TableCell>
                  <TableCell>{candidate.location}</TableCell>
                  <TableCell>{candidate.jobTitle}</TableCell>
                  <TableCell>{candidate.email}</TableCell>
                  <TableCell>{candidate.rate}</TableCell>
                  <TableCell>
                    <Button variant="ghost" size="icon">
                      <FileText className="h-4 w-4" />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Badge className={getStatusColor(candidate.status)}>{candidate.status}</Badge>
                  </TableCell>
                  <TableCell>
                    <Button variant="ghost" size="icon">
                      <MoreVertical className="h-4 w-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {selectedCandidate && (
        <Sheet open={drawerOpen} onOpenChange={setDrawerOpen}>
          <SheetContent className="w-[90vw] max-w-[600px] sm:max-w-[700px] overflow-y-auto" side="right">
            <SheetHeader className="border-b pb-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <SheetTitle className="text-3xl font-semibold">Candidate Details</SheetTitle>
                  <Badge className={getStatusColor(selectedCandidate.status)}>{selectedCandidate.status}</Badge>
                </div>
                <Button 
                  variant="ghost" 
                  size="icon" 
                  onClick={() => setDrawerOpen(false)} 
                  className="h-11 w-11"
                >
                  <X className="h-6 w-6" />
                </Button>
              </div>
            </SheetHeader>

            <div className="py-6 space-y-8">
              <div>
                <h2 className="text-xl font-semibold mb-6">Contact Details</h2>
                <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                  <div>
                    <p className="text-sm text-muted-foreground mb-1">Name</p>
                    <p className="text-lg">{selectedCandidate.name}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground mb-1">Job Title</p>
                    <p className="text-lg">{selectedCandidate.jobTitle}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground mb-1">Email</p>
                    <p className="text-lg">{selectedCandidate.email}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground mb-1">Phone</p>
                    <p className="text-lg">{selectedCandidate.phone}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground mb-1">Location</p>
                    <p className="text-lg">{selectedCandidate.location}</p>
                  </div>
                  <div>
                    <p className="text-sm text-muted-foreground mb-1">Day Rate</p>
                    <p className="text-lg">{selectedCandidate.dayRate}</p>
                  </div>
                </div>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-6">Recent Experience</h2>
                <div className="space-y-6">
                  {selectedCandidate.experiences.map((exp, index) => (
                    <div key={index}>
                      <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                        <div>
                          <p className="text-sm text-muted-foreground mb-1">Production Name</p>
                          <p className="text-lg">{exp.productionName}</p>
                        </div>
                        <div>
                          <p className="text-sm text-muted-foreground mb-1">Year</p>
                          <p className="text-lg">{exp.startYear}</p>
                        </div>
                        <div>
                          <p className="text-sm text-muted-foreground mb-1">Role</p>
                          <p className="text-lg">{exp.role}</p>
                        </div>
                        <div>
                          <p className="text-sm text-muted-foreground mb-1">Show Reel</p>
                          <a 
                            href={exp.showReel} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="text-lg text-blue-500 hover:underline"
                          >
                            View Reel
                          </a>
                        </div>
                      </div>
                      {index < selectedCandidate.experiences.length - 1 && (
                        <Separator className="my-6" />
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex items-center justify-between pt-4 border-t">
                <div>
                  <p className="text-sm text-muted-foreground mb-1">CV</p>
                  <p className="text-lg">{selectedCandidate.cv}</p>
                </div>
                <Button variant="outline" size="sm">
                  <FileText className="mr-2 h-4 w-4" />
                  View CV
                </Button>
              </div>
            </div>

            <SheetFooter className="border-t pt-6">
              <Button onClick={() => setDrawerOpen(false)}>Close</Button>
            </SheetFooter>
          </SheetContent>
        </Sheet>
      )}
    </div>
  )
}